/**
 * Environnement de production.
 */
export const environment: {
  production: boolean;
  envName: string;
  backApiUrl: string;
  baseHref: string;
} = {
  production: true,
  envName: 'prd',
  backApiUrl: 'https://ms.prod.mba-partenaires.com/',
  baseHref: 'https://mf-events.prod.mba-partenaires.com/',
};
